<template>
    <div class="zzry">
        <myhead :param="param" />
        <section>
            <div class="bannerzzry">
                <div style="height:280px; "></div>
                <div style="font-size: 44px;font-weight: bold;color: #ffffff;">资质荣誉</div>
            </div>
        </section>
        <section style="margin-top: 90px;">
            <div class="body1660 htable" >
                <div class="hcell" style="width: 130px;text-align: left;">
                    <div><span class="btLine">国家</span>级荣誉</div>
                </div>
                <div class="hcell htable" style="width: 1400px;justify-content: space-between;">
                    <div class="hcell gjryblock" v-if="rowsgj[0]"  >
                        <div class="gjryimg"  >
                            <img class="mimg" :src="rowsgj[0].tb_image"  :title="rowsgj[0].tb_title">
                        </div>
                        <div class="gjryline"></div>
                        <div class="gjrytitle">{{rowsgj[0].tb_title}}</div>
                    </div><div class="hcell gjryblock" v-else > </div>
                    <div class="hcell gjryblock" v-if="rowsgj[1]"  >
                        <div class="gjryimg"  >
                            <img class="mimg" :src="rowsgj[1].tb_image"  :title="rowsgj[1].tb_title">
                        </div>
                        <div class="gjryline"></div>
                        <div class="gjrytitle">{{rowsgj[1].tb_title}}</div>
                    </div><div class="hcell gjryblock" v-else > </div>
                    <div class="hcell gjryblock" v-if="rowsgj[2]"  >
                        <div class="gjryimg"  >
                            <img class="mimg" :src="rowsgj[2].tb_image"  :title="rowsgj[2].tb_title">
                        </div>
                        <div class="gjryline"></div>
                        <div class="gjrytitle">{{rowsgj[2].tb_title}}</div>
                    </div><div class="hcell gjryblock" v-else > </div>
                    <div class="hcell gjryblock" v-if="rowsgj[3]"  >
                        <div class="gjryimg"  >
                            <img class="mimg" :src="rowsgj[3].tb_image"  :title="rowsgj[3].tb_title">
                        </div>
                        <div class="gjryline"></div>
                        <div class="gjrytitle">{{rowsgj[3].tb_title}}</div>
                    </div><div class="hcell gjryblock" v-else > </div>
                </div>
                <div class="hcell" style="width: 130px;text-align: left;"></div>
            </div>
        </section>

        <section style="margin-top: 90px;">
            <div class="body1660 htable" >
                <div class="hcell" style="width: 130px;text-align: left;">
                    <div><span class="btLine">省部</span>级荣誉</div>
                </div>
                <div class="hcell htable" style="width: 1400px;justify-content: space-between;">
                    <div class="hcell gjryblock" v-if="rowssb[0]"  >
                        <div class="gjryimg"  >
                            <img class="mimg" :src="rowssb[0].tb_image"  :title="rowssb[0].tb_title">
                        </div>
                        <div class="gjryline"></div>
                        <div class="gjrytitle">{{rowssb[0].tb_title}}</div>
                    </div><div class="hcell gjryblock" v-else > </div>
                    <div class="hcell gjryblock" v-if="rowssb[1]"  >
                        <div class="gjryimg"  >
                            <img class="mimg" :src="rowssb[1].tb_image"  :title="rowssb[1].tb_title">
                        </div>
                        <div class="gjryline"></div>
                        <div class="gjrytitle">{{rowssb[1].tb_title}}</div>
                    </div><div class="hcell gjryblock" v-else > </div>
                    <div class="hcell gjryblock" v-if="rowssb[2]"  >
                        <div class="gjryimg"  >
                            <img class="mimg" :src="rowssb[2].tb_image"  :title="rowssb[2].tb_title">
                        </div>
                        <div class="gjryline"></div>
                        <div class="gjrytitle">{{rowssb[2].tb_title}}</div>
                    </div><div class="hcell gjryblock" v-else > </div>
                    <div class="hcell gjryblock" v-if="rowssb[3]"  >
                        <div class="gjryimg"  >
                            <img class="mimg" :src="rowssb[3].tb_image"  :title="rowssb[3].tb_title">
                        </div>
                        <div class="gjryline"></div>
                        <div class="gjrytitle">{{rowssb[3].tb_title}}</div>
                    </div><div class="hcell gjryblock" v-else > </div>
                </div>
                <div class="hcell" style="width: 130px;text-align: left;"></div>
            </div>
        </section>

        <section style="margin-top: 90px;">
            <div class="body1660 htable" >
                <div class="hcell" style="width: 130px;text-align: left;">
                    <div><span class="btLine">自主</span>知识产权</div>
                </div>
                <div class="hcell htable" style="width: 1400px;justify-content: space-between;">
                    <div class="hcell gjryblock" v-if="rowszz[0]"  >
                        <div class="gjryimg"  >
                            <img class="mimg" :src="rowszz[0].tb_image"  :title="rowszz[0].tb_title">
                        </div>
                        <div class="gjryline"></div>
                        <div class="gjrytitle">{{rowszz[0].tb_title}}</div>
                    </div><div class="hcell gjryblock" v-else > </div>
                    <div class="hcell gjryblock" v-if="rowszz[1]"  >
                        <div class="gjryimg"  >
                            <img class="mimg" :src="rowszz[1].tb_image"  :title="rowszz[1].tb_title">
                        </div>
                        <div class="gjryline"></div>
                        <div class="gjrytitle">{{rowszz[1].tb_title}}</div>
                    </div><div class="hcell gjryblock" v-else > </div>
                    <div class="hcell gjryblock" v-if="rowszz[2]"  >
                        <div class="gjryimg"  >
                            <img class="mimg" :src="rowszz[2].tb_image"  :title="rowszz[2].tb_title">
                        </div>
                        <div class="gjryline"></div>
                        <div class="gjrytitle">{{rowszz[2].tb_title}}</div>
                    </div><div class="hcell gjryblock" v-else > </div>
                    <div class="hcell gjryblock" v-if="rowszz[3]"  >
                        <div class="gjryimg"  >
                            <img class="mimg" :src="rowszz[3].tb_image"  :title="rowszz[3].tb_title">
                        </div>
                        <div class="gjryline"></div>
                        <div class="gjrytitle">{{rowszz[3].tb_title}}</div>
                    </div><div class="hcell gjryblock" v-else > </div>
                </div>
                <div class="hcell" style="width: 130px;text-align: left;"></div>
            </div>
        </section>
        <section style="margin-top: 80px;"></section>
    </div>
</template>
<script>
    import myhead  from  '../components/myhead.vue'
    import { listTopicBases } from "../hts/api.js"
    export default {
        name: "",

        components: {
            myhead,

        },
        data() {
            return {
                param: {
                    pageindex: 5,
                },
                rowsgj:[
                   /* {
                        tb_image:require('../assets/zzryt1.png'),
                        tb_title:'中国新能源行业十大创新力品牌',
                    },{
                        tb_image:require('../assets/zzryt1.png'),
                        tb_title:'中国新能源行业十大创新力品牌',
                    },{
                        tb_image:require('../assets/zzryt1.png'),
                        tb_title:'中国新能源行业十大创新力品牌',
                    },{
                        tb_image:require('../assets/zzryt1.png'),
                        tb_title:'中国新能源行业十大创新力品牌',
                    }*/
                    ],
                rowssb:[
                    /*{
                        tb_image:require('../assets/zzryt1.png'),
                        tb_title:'重庆市科学技术奖(科技进步二等奖)',
                    },{
                        tb_image:require('../assets/zzryt1.png'),
                        tb_title:'中国新能源行业十大创新力品牌',
                    },{
                        tb_image:require('../assets/zzryt1.png'),
                        tb_title:'中国新能源行业十大创新力品牌',
                    },{
                        tb_image:require('../assets/zzryt1.png'),
                        tb_title:'中国新能源行业十大创新力品牌',
                    }*/
                    ],
                rowszz:[
                    /*{
                        tb_image:require('../assets/zzryt1.png'),
                        tb_title:'中国新能源行业十大创新力品牌',
                    },{
                        tb_image:require('../assets/zzryt1.png'),
                        tb_title:'中国新能源行业十大创新力品牌',
                    },
                    {
                        tb_image:require('../assets/zzryt1.png'),
                        tb_title:'中国新能源行业十大创新力品牌',
                    },{
                        tb_image:require('../assets/zzryt1.png'),
                        tb_title:'中国新能源行业十大创新力品牌',
                    }*/
                    ],
            }
        },
        mounted() {
            this.getList ()
        },
        methods: {
            getList () {
                // console.log(this.id)
                listTopicBases({type:'honor:[countryHonor,provinceHonor,rightHonor]', page: this.param.page, rows: this.param.rows })
                    .then(resData => {
                        this.rowsgj=resData.data.countryHonor
                        this.rowssb=resData.data.provinceHonor
                        this.rowszz=resData.data.rightHonor
                        //alert(res.data.rows[0].tb_content)
                        //alert(data.rows[0].tb_content)
                    })
                    .catch()
            },
        },
    }
</script>
<style lang="css" scoped>

    .zzry {
        width: 100%;
    }
    .bannerzzry{
        background-image: url('~assets/zzry.png');
        height: 600px;
    }
    .btLine{
        border-bottom:2px solid #d7000f;
        padding-bottom: 20px;

    }
    .gjryblock{
        width: 310px;
        height: 460px;
        overflow: hidden;
        background-color:rgba(230,230,230,0.5);
    }

    .gjryimg{
        height: 306px;
        margin-top: 40px;
        overflow: hidden;
    }
    .mimg{
        width: 226px;
    }
    .gjryline{
        margin: 28px 24px 20px 24px ;
        height: 2px;
        border-bottom: 1px solid rgba(201,201,201,0.8);;
    }
    .gjrytitle{
        font-size: 18px;
        margin-left: 24px;
        margin-right: 24px;
        color: #202020;
    }
</style>
